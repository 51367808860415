'use client';

import Image from 'next/image';
import { usePathname, useRouter } from 'next/navigation';
import { useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { twMerge } from 'tailwind-merge';
import { useDisconnect } from 'wagmi';

import useCurrentUser from '@api/user/auth/queries/useCurrentUser';
import { useGetPurchaseInfo } from '@api/user/profile/queries/usePurchaseInfoQuery';

import LoadingIcon from '@components/loading/LoadingIcon';
import PopoverContainer from '@components/popovers/PopoverContainer';
import PopoverItem from '@components/popovers/PopoverItem';

import { formatDisplayCurrency } from '@features/utils/numbers';

import {
  ChevronDownIcon,
  Logout,
  ProfileUserIcon,
  UserIcon,
} from '@shared/assets';
import {
  FORUM_KEYS,
  PRODUCTS,
  PRODUCT_DETAILS,
  USER_ME,
} from '@shared/constants/queryKeys';
import { useOutsideClick } from '@shared/hooks';
import { PROFILE } from '@shared/utils';
import {
  removeAccessTokenForCurrentUser,
  removeRefreshTokenForCurrentUser,
} from '@shared/utils/auth';

const UserActionsDropdown = () => {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const popoverRef = useOutsideClick(() => setIsUserMenuOpen(false));
  const router = useRouter();
  const queryClient = useQueryClient();
  const { disconnect } = useDisconnect();
  const { data: userData } = useCurrentUser();
  const { data: purchaseData, isPending } = useGetPurchaseInfo();
  const pathname = usePathname();

  const logout = async () => {
    setIsUserMenuOpen(false);

    await removeAccessTokenForCurrentUser();
    await removeRefreshTokenForCurrentUser();

    disconnect();

    queryClient.removeQueries({ queryKey: USER_ME });
    queryClient.invalidateQueries({ queryKey: [PRODUCTS] });
    queryClient.invalidateQueries({ queryKey: [PRODUCT_DETAILS] });
    queryClient.invalidateQueries({
      queryKey: [FORUM_KEYS.notifications.notificationsList],
    });

    router.refresh();
  };

  const handleProfileClick = () => {
    setIsUserMenuOpen(false);
    if (!pathname?.startsWith(PROFILE)) {
      router.push(PROFILE, { scroll: false });
    }
  };

  return (
    <div className="relative flex h-full w-max" ref={popoverRef}>
      <button onClick={() => setIsUserMenuOpen((prevState) => !prevState)}>
        <div className="flex h-full items-center gap-4 md:px-4">
          {userData?.data?.avatar ? (
            <div className="relative h-16 w-16 md:h-10 md:w-10">
              <Image
                src={userData?.data?.avatar}
                alt="User image"
                fill
                className="object-cover md:rounded-full"
              />
            </div>
          ) : (
            <div className="nav-bar-icon h-full">
              <ProfileUserIcon className="size-6" />
            </div>
          )}
          <div className="hidden flex-col items-start md:flex">
            {isPending ? (
              <LoadingIcon />
            ) : (
              <>
                <span className="label-xlarge">
                  {formatDisplayCurrency(purchaseData?.data.balanceAvailable)}
                </span>
                <span className="font-xsmall text-neutral400">
                  Account balance
                </span>
              </>
            )}
          </div>
          <ChevronDownIcon
            className={twMerge(
              'hidden w-4 transition-all md:block',
              isUserMenuOpen && 'rotate-180'
            )}
          />
        </div>
      </button>

      <PopoverContainer
        isOpen={isUserMenuOpen}
        className="-left-24 top-18 w-full md:left-0"
      >
        <PopoverItem
          text="My Profile"
          onClick={handleProfileClick}
          icon={UserIcon}
        />
        <PopoverItem
          text="Logout"
          onClick={async () => await logout()}
          icon={Logout}
        />
      </PopoverContainer>
    </div>
  );
};

export default UserActionsDropdown;
